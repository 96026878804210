<template>
  <v-layout>
    <v-row justify="center" no-gutters>
      <v-col cols="12">
        <kurcc-back-button :to="{name: 'login'}"/>
      </v-col>
      <v-col class="mt-n16" cols="10" md="4" sm="6" xl="2">
        <v-row align="center" justify="center" no-gutters>
          <v-col>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="login">
                <p class="mt-5 text-h6 text-start">
                  {{ $vuetify.lang.t('$vuetify.pages.auth.verificationTitle') }}
                </p>
                <p class="mt-5 text-start">
                  {{ $vuetify.lang.t('$vuetify.pages.auth.sendVerificationTo') }} <strong>{{ phone_number }}</strong>
                </p>
                <validation-provider v-slot="{ errors }" name="Verification Code" rules="required">
                  <v-text-field v-model="verificationCode" :error-messages="errors"
                                :label="$vuetify.lang.t('$vuetify.pages.auth.verificationCode')"
                                autofocus clearable outlined/>
                </validation-provider>
                <v-btn :disabled="invalid" :loading="loading" color="primary" tile type="submit" width="100%">
                  {{ $vuetify.lang.t('$vuetify.pages.auth.login') }}
                </v-btn>
                <v-row justify="space-between" no-gutters>
                  <v-btn :to="{name: 'login'}" class="mt-5" color="primary" outlined small text tile width="150px">
                    {{ $vuetify.lang.t('$vuetify.pages.auth.editMyNumber') }}
                  </v-btn>
                  <countdown v-if="countingDown" :emit-events="true" :time="30000" @end="countingDown = false"
                             @start="countingDown = true">
                    <template slot-scope="props">
                      <v-btn :disabled="props.seconds > 0" class="mt-5" color="primary" outlined small text tile
                             width="150px">
                        {{ $vuetify.lang.t('$vuetify.pages.auth.resendCodeIn') }} {{ props.seconds }}
                      </v-btn>
                    </template>
                  </countdown>
                  <v-btn v-else :loading="resendingCode" class="mt-5" color="primary" outlined small text tile
                         width="150px"
                         @click="requestCode">
                    {{ $vuetify.lang.t('$vuetify.pages.auth.resendCode') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccVerifyCodePage',
  components: {
    KurccBackButton: () => import('@/modules/app/components/KurccBackButton'),
    ValidationObserver
  },
  data () {
    return {
      verificationCode: null,
      loading: false,
      resendingCode: false,
      countingDown: true
    }
  },
  computed: {
    phone_number () {
      return localStorage.getItem('phone_number')
    }
  },
  methods: {
    login () {
      this.loading = true
      this.$store.dispatch('login', {
        phone_number: this.phone_number,
        one_time_password: this.verificationCode
      }).then(() => {
        localStorage.removeItem('phone_number')
      }).finally(() => {
        this.loading = false
      })
    },
    requestCode () {
      this.resendingCode = true
      this.$store.dispatch('requestPassword', {
        phone_number: this.phone_number,
        resend: true
      }).then(() => {
        this.countingDown = true
      }).finally(() => {
        this.resendingCode = false
      })
    }
  }
}
</script>
